<template>
    <div>
      <el-row ref="search_form">
        <el-col :span="24">
          <div class="search-box">
            
            
        
        
            
  
   
           
  
          </div>
        </el-col>
      </el-row>
  
      <el-table
      
      :cell-style="columnStyle"
        :data="tableData"
        ref="elTable"
        :header-cell-style="{backgroundColor:'#f4f4f5',textAlign:'center'}"
        :height="tableHeight"
        style="width: 100%"
        border
       >
        <el-table-column
        highlight-current-row
          type="selection"
          align="center"
          width="55">
         
        </el-table-column>
        <!-- <template slot-scope="scope">
                  
                  <el-radio @change="xuanzhong" v-model="currentFactor" :label="scope.row">{{""}}</el-radio>
                </template> -->
        <!-- <el-table-column label="单选" width="50" align="center">
                
              </el-table-column> -->
        <el-table-column
          fixed
          align="center"
          prop="id"
          label="序号"
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          fixed
          align="center"
          prop="sortBy"
          label="排序"
          min-width="50">
          <template slot-scope="scope">
            <el-button slot="reference" type="text" size="small" style="color: #606266" @click="showEditSortBy(scope.row, scope.$index)">{{ scope.row.sortBy }}</el-button>
  <!--          <el-popover-->
  <!--            placement="top-start"-->
  <!--            trigger="manual"-->
  <!--            v-model="editSortBy.v['v'+scope.$index]"-->
  <!--            width="160">-->
  <!--            <el-input v-model="editSortBy.sortByEditValue" size="mini" placeholder="请填写排序"></el-input>-->
  <!--            <div style="text-align: right; margin: 0px; margin-top: 10px;">-->
  <!--              <el-button type="primary" size="mini" @click="updateSortBy(scope.row, scope.$index)">确定</el-button>-->
  <!--            </div>-->
  <!--            <el-button slot="reference" type="text" size="small" style="color: #606266" @click="showEditSortBy(scope.row, scope.$index)">{{ scope.row.sortBy }}</el-button>-->
  <!--          </el-popover>-->
          </template>
        </el-table-column>
        <el-table-column
          fixed
          align="center"
          prop="username"
          label="姓名"
          max-width="60"
           show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="postRank"
          label="职级"
           show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="status"
          label="参会状态"
           show-overflow-tooltip>
          <template slot-scope="scope">
            <template v-if="scope.row.status == -1">取消</template>
            <template v-else-if="scope.row.status == 0">审核中</template>
            <template v-else-if="scope.row.status == 1">审核通过</template>
            <template v-else-if="scope.row.status == 2">暂不确定</template>
            <template v-else-if="scope.row.status == 3">确认参加</template>
            <template v-else-if="scope.row.status == -2">嘉宾拒绝</template>
            <template v-else-if="scope.row.status == -3">已删除</template>
          </template>
        </el-table-column>
       
        <el-table-column
          align="center"
          prop="company"
          label="单位"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="post"
          label="职务"
           show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="userTypeName"
          label="嘉宾类型"
           show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column
       
          prop="hyId"
          align="left"
          label="已确认板块"
          show-overflow-tooltip
          >
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="queren(scope.row,1)">已确认板块</el-button>
            <br>
            <div v-html="parsePlate(scope.row,1)"></div>
            <span v-if="!parsePlate(scope.row,1)">
              共0条
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="hyId"
          align="left"
          label="待确认板块"
          show-overflow-tooltip
         >
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="queren(scope.row,0)">待确认板块</el-button>
            <br>
            <div v-html="parsePlate(scope.row,0)"></div>
            <span v-if="!parsePlate(scope.row,0)">
              共0条
            </span>
          </template>
        </el-table-column> -->
        <el-table-column
          align="center"
          prop="yyCompanyName"
          show-overflow-tooltip
          label="邀约单位"
          >
        </el-table-column>
        
        <el-table-column
          align="center"
          prop="sxList"
          label="随行人员"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-html="fmtSx(scope.row)"></span>
          </template>
        </el-table-column>
  
        <el-table-column
          align="center"
          prop="note2"
          label="嘉宾活动安排备注"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
        show-overflow-tooltip
          align="center"
          prop="note"
          label="嘉宾个人需求备注"
          >
        </el-table-column>
        <el-table-column
        show-overflow-tooltip
          align="center"
          prop="xingchengZt"
          label="当前行程状态"
         >
        </el-table-column>
        <el-table-column
          align="center"
          prop="hotelName"
          label="酒店"
           show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="arriveTime"
          label="抵青时间"
           show-overflow-tooltip>
          <template slot-scope="scope">
            {{fmtDate2(scope.row.arriveTime)}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="arriveMode"
          label="抵达方式"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="frequency"
          label="抵达航班/车次信息"
           show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="carnum"
          label="抵达车牌"
           show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="arriveStation"
          label="接站口"
           show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="arriveTime"
          label="离青时间"
           show-overflow-tooltip>
          <template slot-scope="scope">
            {{fmtDate2(scope.row.backTime)}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="backMode"
          label="抵达方式"
           show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="backFrequency"
          label="抵达航班/车次信息"
           show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="backCarnum"
          label="抵达车牌"
           show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="lkdd"
          label="送站口"
           show-overflow-tooltip>
        </el-table-column>
  
  
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
           show-overflow-tooltip>
          <template slot-scope="scope">
            {{fmtDate(new Date(scope.row.createTime*1000))}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="createUserName"
          label="创建人"
           show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          align="center"
          prop="updateTime"
          label="最新修改时间"
           show-overflow-tooltip>
          <template slot-scope="scope">
            {{fmtDate(new Date(scope.row.updateTime*1000))}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="updateUserName"
          label="最新修改人"
           show-overflow-tooltip>
        </el-table-column>
  
       
  <!--      <el-table-column-->
  <!--        align="center"-->
  <!--        prop="address"-->
  <!--        label="行程信息"-->
  <!--         show-overflow-tooltip>-->
  <!--        <template slot-scope="scope">-->
  <!--          <el-button size="mini" type="success" @click="genXCTP(scope.$index, scope.row)">生成行程单</el-button>-->
  <!--        </template>-->
  <!--      </el-table-column>
  
         <el-table-column
        fixed="right"
          align="center"
          prop="address"
          label="审核"
          min-width="50">
          <template slot-scope="scope">
            <el-button size="mini" type="success" @click="submitSH(scope.$index, scope.row)" v-if="scope.row.status==3">审核</el-button>
          </template>
        </el-table-column> -->
  
        <el-table-column
        fixed="right"
          align="center"
          prop="address"
          label="操作"
          min-width="180">
          <template slot-scope="scope"> 
              <el-button size="mini" type="primary" @click="submitSH(scope.$index, scope.row)">继续参会</el-button>
          </template>
        </el-table-column>
        <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
      </el-table>
      <el-row>
        <el-col :span="24" class="pagination-box">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            :page-size="pageSize"
            :page-sizes="[20, 50, 100]"
            :total="total"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </el-col>
      </el-row>
  
      <el-dialog :title="editForm.id==0?'新增嘉宾':'修改嘉宾'" :visible.sync="editFormVisible" width="80%" top="1vh" :close-on-click-modal="false">
        <el-form :model="editForm" ref="editForm" :rules="editRules">
          <el-form-item label="嘉宾类型" label-width="120px" prop="userTypeId">
            <el-radio v-for="(item,index) in userTypeList" v-model="editForm.userTypeId" :label="item.id" v-if="index>0" @change="onUserTypeChange">{{ item.name }}</el-radio>
          </el-form-item>
          <el-row v-if="addShowFlag">
            <el-col :span="8">
              <el-form-item label="绑定用户" label-width="120px" prop="frontUserId">
                <el-select v-model="editForm.frontUserId" size="small" clearable filterable placeholder="直接选择或搜索选择">
                  <el-option
                    v-for="item in frontUserList"
                    :key="item.id"
                    :label="item.nickname+'('+item.phoneNum+')'"
                    :value="item.id">
                  </el-option>
                </el-select>
  
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="绑定联络员" label-width="120px" prop="handlerId">
                <el-select v-model="editForm.handlerId" size="small" clearable filterable placeholder="直接选择或搜索选择">
                  <el-option
                    v-for="(item,index) in userList"
                    :key="item.id"
                    :label="item.nickname+'('+item.username+')'"
                    :value="item.id" v-if="index>0 && item.roleId==6">
                  </el-option>
                </el-select>
  <!--              <el-input size="small" v-model="editForm.handlerId" autocomplete="off" placeholder="直接选择或搜索选择"></el-input>-->
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
  
          <el-row v-if="editForm.userTypeId==6">
            <el-col :span="8">
              <el-form-item label="贵宾" label-width="120px" prop="ppid">
                <el-select size="small" v-model="editForm.ppid" filterable placeholder="请选择">
                  <el-option
                    v-for="item in gbList"
                    :key="item.id"
                    :label="item.username"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
            <el-col :span="8"></el-col>
          </el-row>
  
          <el-row>
            <el-form-item label="是否排序" label-width="120px">
            <el-switch
    v-model="paixu"
    active-color="#13ce66"
    inactive-color="#ff4949">
  </el-switch>
          </el-form-item>
          <el-form-item label="排序" v-if="paixu==true" label-width="120px">
            <el-input v-model="editForm.sortBy" placeholder="请输入排序" />
          </el-form-item>
            <el-col :span="8">
              <el-form-item label="姓名" label-width="120px" prop="username">
                <el-input size="small" v-model="editForm.username" autocomplete="off" placeholder="请输入参会人员姓名" @blur="checkSameName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="性别" label-width="120px" prop="gender">
                <el-radio-group size="small" v-model="editForm.gender">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="联系方式" label-width="120px" prop="phone">
                <el-input size="small" v-model="editForm.phone" autocomplete="off" placeholder="请输入参会人联系方式" maxlength="11"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话类型" label-width="120px" prop="phoneType">
                <el-select
                  v-model="editForm.phoneType"
                  size="small"
                  autocomplete="off"
                  filterable
                  allow-create
                  default-first-option
                  placeholder="选择联系电话类型">
                  <el-option key="本人" label="本人" value="本人"></el-option>
                  <el-option key="助理" label="助理" value="助理"></el-option>
  
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="邮箱" label-width="120px" prop="email">
                <el-input size="small" v-model="editForm.email" autocomplete="off" placeholder="请输入参会人邮箱" maxlength="50"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="单位" label-width="120px" prop="company">
                <el-input size="small" v-model="editForm.company" autocomplete="off" placeholder="请输入参会人员单位"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="职务" label-width="120px" prop="handlerId">
                <el-input size="small" v-model="editForm.post" autocomplete="off" placeholder="请输入参会人员职务"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="职级" label-width="120px" prop="postRank">
                <el-input size="small" v-model="editForm.postRank" autocomplete="off" placeholder="请输入参会人员职级"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="editForm.userTypeId==1||editForm.userTypeId==2">
            <el-col :span="8">
              <el-form-item label="迎送领导" label-width="120px" prop="yslingdao">
                <el-input size="small" v-model="editForm.yslingdao" autocomplete="off" placeholder="如需,请填入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="迎送备注" label-width="120px" prop="ysdesc">
                <el-input size="small" v-model="editForm.ysdesc" autocomplete="off" placeholder="如需,请填入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="国籍" label-width="120px" prop="country">
                <el-input size="small" v-model="editForm.country" autocomplete="off" placeholder="国籍"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="证件号码" label-width="120px" prop="idcard">
                <el-input size="small" v-model="editForm.idcard" autocomplete="off" placeholder="请输入证件号码,身份证或护照"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="证件" label-width="120px" prop="idCardType">
                <el-radio-group size="small" v-model="editForm.idCardType">
                  <el-radio :label="0">身份证</el-radio>
                  <el-radio :label="1">护照</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="地址" label-width="120px" prop="sheng">
                <el-cascader
                  size="small"
                  clearable
                  class="elCacader"
                  v-model="selectValue"
                  :props="defultProp"
                  :options="address"
                  @change="handleAddressChange"
                ></el-cascader>
                {{selectValue}}
              </el-form-item>
            </el-col>
          </el-row>
          
          
          <el-form-item label="邀请参会" label-width="120px" prop="hyIds">
            <el-tabs v-model="tabsActiveName" type="border-card">
              <el-tab-pane v-for="(item,index) in plateWithGroupList" :label="item.title" :name="item.title">
                <el-checkbox-group v-model="editForm.hyIds" @change="handleCheckedCitiesChange">
                  <el-checkbox v-for="(item2,index2) in item.plateList" :label="item2.id" :key="index2" name="type">{{item2.title}}<span style="color: #409EFF">({{fmtDate2(item2.startTime)}})</span></el-checkbox>
                </el-checkbox-group>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
  
          <el-form-item label="邀约单位/部门" label-width="120px" prop="yyCompanyId">
            <el-radio v-for="(item,index) in yyCompanyList" v-model="editForm.yyCompanyId" :label="item.id" v-if="index>0">{{ item.name }}</el-radio>
          </el-form-item>
  <!--        <el-form-item label="部门组" label-width="120px" prop="workGroup">-->
  <!--          <el-radio v-for="(item,index) in workGroupList" v-model="editForm.workGroup" :label="item.title" v-if="index>0">{{ item.title }}</el-radio>-->
  <!--        </el-form-item>-->
          <el-form-item label="付费方式" label-width="120px" prop="qy">
            <el-radio v-for="(item,index) in qyList" v-model="editForm.qy" :label="item.value" v-if="index>0">{{ item.label }}</el-radio>
            <el-input size="small" v-model="editForm.qyOther" autocomplete="off" placeholder="选择其他,请填写" style="width: 300px;"></el-input>
            <div style="color: red">部分嘉宾费用由承办方全部承担，原则上青岛市内嘉宾不承担住宿费。</div>
          </el-form-item>
          <el-form-item label="嘉宾活动安排备注"  prop="note2">
            <el-input size="small" v-model="editForm.note2" autocomplete="off" placeholder="填写嘉宾是否有签约，座谈、颁奖、主持等活动"></el-input>
          </el-form-item>
          <el-form-item label="嘉宾个人需求备注" label-width="130px" prop="note">
            <el-input size="small" v-model="editForm.note" autocomplete="off" placeholder="请输入备注信息,如宗教信仰民族等"></el-input>
          </el-form-item>
          <el-form-item label="是否参会" label-width="120px" prop="status">
            <el-radio v-model="editForm.status" :label="2">待定</el-radio>
            <el-radio v-model="editForm.status" :label="3">确认参加</el-radio>
          </el-form-item>
          <!-- <el-form-item label="疫情登记" label-width="120px" prop="yq">
            <el-radio v-model="editForm.yq" :label="0">7天内未去过中高风险地区</el-radio>
            <el-radio v-model="editForm.yq" :label="1">7天内去过中高风险地区</el-radio>
          </el-form-item> -->
          <el-form-item label="人脸识别图片" label-width="120px" prop="faceImg">
            <el-upload
              class="avatar-uploader"
              name="file"
              :action="uploadActionUrl"
              accept=".png,.jpeg,.jpg"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :on-error="handleAvatarError">
              <img v-if="editForm.faceImg" :src="editForm.faceImg" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
  
        <div slot="footer" class="dialog-footer">
          <el-button @click="editFormVisible = false">取 消</el-button>
          <el-button type="primary" @click.native.prevent="submitForm" :loading="loading">{{ editForm.id==0?'添 加':'修 改' }}</el-button>
        </div>
      </el-dialog>
  
      <el-dialog :title="genImgTitle" :visible.sync="yyhDialogVisible" width="80%" :close-on-click-modal="false">
        <el-form :model="editForm" ref="editForm" :rules="editRules">
          <el-row>
            <el-col :span="8">
              <vue-canvas-poster v-if="genPoster" :widthPixels="0" :painting="painting" @success="genYYHSuccess" @fail="genYYHFail"></vue-canvas-poster>
            </el-col>
            <el-col :span="8">
              <span style="font-size:14px">当前邀请人参会状态为：{{zhuangtai}}</span>
              <div v-if="XCXScanTime" style="font-size: 18px;font-weight: 500;margin-bottom: 10px;">(已被扫码)
                  操作人：{{leixing}}
                  操作时间：{{checkTime}}
              </div>
              <div v-else style="font-size: 18px;font-weight: 500;margin-bottom: 10px;">(未被扫码)
                
  
            </div>
  
              <img :src="yyhImg" style="width: 360px;height: 630px;mode: aspectFill"></img>
            </el-col>
            <el-col :span="8" style="font-size: 20px;float: right;">
              <div v-if="H5ScanTime" style="font-size: 18px;font-weight: 500;margin-bottom: 10px;">(H5邀请函已被打开过)</div>
              <div v-else style="font-size: 18px;font-weight: 500;margin-bottom: 10px;">(H5邀请函未被打开过)</div>
  
              短信{{yyInfoSendStatus}}:<br>
              {{yyInfoSMS}}
  
            </el-col>
          </el-row>
        </el-form>
  
        <div slot="footer" class="dialog-footer">
          <el-button @click="yyhDialogVisible = false">关 闭</el-button>
          <el-button type="primary" @click.native.prevent="sendYYSMS" :loading="loading">发送邀请短信</el-button>
         <!-- <el-button type="primary" @click.native.prevent="sendYYEmail" :loading="loading">发送邀请邮件</el-button> -->
  <!--        <el-button type="info" :loading="loading">发送邀请邮件</el-button>-->
          <el-button type="primary" @click.native.prevent="saveImg" :loading="loading">保存图片</el-button>
        </div>
      </el-dialog>
  
      <el-dialog title="修改抵离信息" close-on-press-escape :visible.sync="editDLFormVisible" width="80%" :close-on-click-modal="false">
        <el-form :model="editDLForm" ref="editDLForm" :rules="editDLRules">
          <el-row>
            <el-col :span="8">
              <el-form-item label="姓名" label-width="120px" prop="username">
                <el-input size="small" :disabled="true" v-model="editDLForm.username" autocomplete="off" placeholder="请输入参会人员姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系方式" label-width="120px" prop="phone">
                <el-input size="small" :disabled="true" v-model="editDLForm.phone" autocomplete="off" placeholder="请输入参会人联系方式" maxlength="11"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="性别" label-width="120px" prop="gender">
                <el-radio-group size="small" :disabled="true" v-model="editDLForm.gender">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="预计抵青时间" label-width="120px" prop="esArriveTime">
                <el-date-picker
                  v-model="dlDate.date1"
                  size="mini"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="timestamp"
                  type="datetime"
                  placeholder="请选择日期和时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="抵青时间" label-width="120px" prop="arriveTime">
                <el-date-picker
                  v-model="dlDate.date2"
                  size="mini"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="timestamp"
                  type="datetime"
                  placeholder="请选择日期和时间">
                </el-date-picker>
              </el-form-item>
            </el-col> -->
            <el-col :span="8"></el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="抵青方式" label-width="120px" prop="arriveMode">
                <el-radio-group v-model="editDLForm.arriveMode" @change="xuanze">
                  <el-radio label="飞机">飞机</el-radio>
                  <el-radio label="高铁">高铁</el-radio>
                  <el-radio label="自驾">自驾</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="" label-width="0px" prop="carnum">
                <el-input size="small" v-model="editDLForm.carnum" autocomplete="off" placeholder="如选择自驾,请输入车牌号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <!-- <el-form-item label="抵青班次" label-width="120px" prop="frequency">
                <el-input size="small" v-model="editDLForm.frequency" autocomplete="off" placeholder="请输入抵青班次"></el-input>
              </el-form-item> -->
              <el-form :inline="true" v-if="editDLForm.arriveMode=='高铁'" :model="formInline" class="demo-form-inline">
    <el-form-item label="高铁班次">
      <el-autocomplete class="my-autocomplete" v-model="editDLForm.frequency"
   :fetch-suggestions="querySearch" placeholder="请输入内容" @select="handleSelect">
              <template slot-scope="{ item }">
                <div class="userId">{{ item.userId }}</div>
                <span class="username" style="font-size: 12px;
          color: #b4b4b4;">{{ item.station_train_code }}</span>
              </template>
   </el-autocomplete>
    </el-form-item>
      <el-form-item label="高铁时间">      
   <el-date-picker
   v-model="timeValue.stockDate"
        value-format="yyyy-MM-dd"
        align="right"
        type="date"
        placeholder="选择日期"
        :picker-options="pickerOptions"
        >
      </el-date-picker>
              </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
    <el-form-item label="选择高铁始发地" v-if="shifadi==true||editDLForm.fromCity" prop="fromCity">
          <el-select v-model="editDLForm.fromCity" placeholder="请选择来源地">
            <el-option
              v-for="item in options1"
              :key="item.id"
              :label="item.station_name"
              :value="item.station_name"
              @click.native="chooseone(item)"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="高铁始发时间" v-if="shifadi==true">      
   <el-date-picker
   v-model="gaotieone"
   readonly
        value-format="timestamp"
        align="right"
        type="datetime"
        placeholder="选择日期"
        :picker-options="pickerOptions"
        >
      </el-date-picker>
              </el-form-item>
              <el-form-item label="选择高铁抵达地" v-if="shifadi==true" prop="fromCity">
          <el-select v-model="gaotiedaodaone" placeholder="请选择来源地">
            <el-option
              v-for="item in options11"
              :key="item.id"
              :label="item.station_name"
              :value="item.station_name"
              @click.native="chosedidaone(item)"
            />
          </el-select>
          
        </el-form-item>
        <el-form-item label="抵达时间" prop="didashijian" v-if="shifadi==true||didashijian" >
            <!-- <el-input readonly  v-model="didashijian"></el-input>  -->
            <el-date-picker
   v-model="didashijian"
        value-format="timestamp"
        align="right"
        type="datetime"
        placeholder="选择日期"
        :picker-options="pickerOptions"
        >
      </el-date-picker>
    </el-form-item>
  </el-form>
  <el-form :inline="true" v-else-if="editDLForm.arriveMode=='飞机'" :model="formInline" class="demo-form-inline">
    <el-form-item label="航班编号">
      <el-input v-model="editDLForm.frequency" placeholder="抵青航班"></el-input>
    </el-form-item>
      <el-form-item label="航班时间">
        <el-date-picker
        value-format="yyyy-MM-dd HH:mm:ss"
        v-model="searchForm.date"
        type="datetime"
        placeholder="选择日期时间">
      </el-date-picker>
              </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmitwo">查询</el-button>
    </el-form-item>
    <el-form-item label="选择飞机始发地" v-if="shifadi==true||editDLForm.fromCity" prop="fromCity">
          <el-select v-model="editDLForm.fromCity" placeholder="请选择来源地">
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.start_CITY"
              :value="item.start_CITY"
              @click.native="choosepall(item)"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="起飞时间" v-if="shifadi==true" prop="arriveTime">
      <!-- <el-input v-model="getfeijione" v-if="shifadi==true" readonly placeholder="航班时间"></el-input> -->
      <el-date-picker
      readonly
   v-model="getfeijione"
        value-format="timestamp"
        align="right"
        type="datetime"
        placeholder="选择日期"
        :picker-options="pickerOptions"
        >
      </el-date-picker>
        </el-form-item>
        <el-form-item label="选择飞机抵达地" v-if="shifadi==true" prop="fromCity">
          <el-select v-model="feijididadi" placeholder="请选择飞机抵达地">
            <el-option
              v-for="item in options12"
              :key="item.id"
              :label="item.end_CITY"
              :value="item.end_CITY"
              @click.native="didafeijishijian(item)"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="抵达时间" v-if="shifadi==true||didashijiantwo"  >
            <!-- <el-input readonly  v-model="didashijiantwo"></el-input>  -->
            <el-date-picker
   v-model="didashijiantwo"
        value-format="timestamp"
        align="right"
        type="datetime"
        placeholder="选择日期"
        :picker-options="pickerOptions"
        >
      </el-date-picker>
    </el-form-item>
  </el-form>
            </el-col>
            <el-col :span="8">
              <el-form-item label="抵青接站口" label-width="120px" prop="arriveStation">
                <el-radio-group v-model="editDLForm.arriveStation">
                  <el-radio v-for="(item,index) in jzkList" :label="item.label">{{ item.value }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="8">
              <el-form-item label="离青时间" label-width="120px" prop="backTime">
                <el-date-picker
                  v-model="dlDate.date3"
                  size="mini"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="timestamp"
                  type="datetime"
                  placeholder="请选择日期和时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
            <el-col :span="8"></el-col>
          </el-row> -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="离青方式" label-width="120px" prop="backMode">
                <el-radio-group v-model="editDLForm.backMode">
                  <el-radio label="飞机">飞机</el-radio>
                  <el-radio label="高铁">高铁</el-radio>
                  <el-radio label="自驾">自驾</el-radio>
                </el-radio-group>
              </el-form-item>
              </el-col>
              <el-col :span="8">
              <el-form-item label="" label-width="0px" prop="backCarnum">
                <el-input size="small" v-model="editDLForm.backCarnum" autocomplete="off" placeholder="如选择自驾,请输入车牌号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form :inline="true" v-if="editDLForm.backMode=='高铁'" :model="formInline" class="demo-form-inline">
    <el-form-item label="离青班次">
      <el-autocomplete class="my-autocomplete" v-model="editDLForm.backFrequency"
   :fetch-suggestions="querySearchAsync" placeholder="请输入离青班次" @select="handleSelectwo">
              <template slot-scope="{ item }">
                <div class="userId">{{ item.userId }}</div>
                <span class="username" style="font-size: 12px;
          color: #b4b4b4;">{{ item.station_train_code }}</span>
              </template>
   </el-autocomplete>
    </el-form-item>
      <el-form-item label="班次时间">      
   <el-date-picker
   v-model="livetime"
        value-format="yyyy-MM-dd"
        align="right"
        type="date"
        placeholder="选择日期"
       
        >
      </el-date-picker>
              </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmitfour">查询</el-button>
    </el-form-item>
    <el-form-item label="选择去源地" v-if="chufadi==true||editDLForm.backCity" prop="backCity">
          <el-select v-model="editDLForm.backCity" placeholder="请选择去源地">
            <el-option
              v-for="item in options9"
              :key="item.id"
              :label="item.station_name"
              :value="item.station_name"
              @click.native="choosetwoall(item)"
            />
          </el-select>
        </el-form-item>
      
        <el-form-item label="高铁离青时间" v-if="shifadi==true||gaotietwo">      
   
      <el-input v-model="gaotietwo"  readonly placeholder="高铁离青时间"></el-input>
  
              </el-form-item>
  </el-form>
              <el-form  :inline="true" v-if="editDLForm.backMode=='飞机'" :model="formInline" class="demo-form-inline">
                <el-form-item label="航班编号" >
      <el-input v-model="editDLForm.backFrequency" placeholder="离青航班"></el-input>
    </el-form-item>
      <el-form-item label="航班时间">
        <el-date-picker
        value-format="yyyy-MM-dd HH:mm:ss"
        v-model="leftshijian"
        type="datetime"
        placeholder="选择日期时间">
      </el-date-picker>
              </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmithree">查询</el-button>
    </el-form-item>
    <el-form-item label="选择去源地" v-if="chufadi==true||editDLForm.backCity" prop="backCity">
          <el-select v-model="editDLForm.backCity" placeholder="请选择去源地">
            <el-option
              v-for="item in options3"
              :key="item.id"
              :label="item.START_CITY"
              :value="item.START_CITY"
              @click.native="chooseall(item)"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="离青时间" v-if="chufadi==true||getfeijitwo">
      <el-input v-model="getfeijitwo"  readonly placeholder="离青航班时间"></el-input>
  
        </el-form-item>
              </el-form>
              </el-col>
              <el-col :span="8">
                <el-form-item label="离青送站口" label-width="120px" prop="lkdd">
                <el-radio-group v-model="editDLForm.lkdd">
                  <el-radio v-for="(item,index) in jzkList" :label="item.label">{{ item.value }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="8">
              <el-form-item label="离青班次" label-width="120px" prop="backFrequency">
                <el-input size="small" v-model="editDLForm.backFrequency" autocomplete="off" placeholder="请输入离青班次"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="离青送站口" label-width="120px" prop="lkdd">
                <el-radio-group v-model="editDLForm.lkdd">
                  <el-radio v-for="(item,index) in jzkList" :label="item.label">{{ item.value }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row> -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="当前付费方式" label-width="120px" prop="">
                {{ editDLForm.dqqy }}
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
            <el-col :span="8"></el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="分配酒店" label-width="120px" prop="hotelId">
                <el-radio-group v-model="editDLForm.hotelId">
                  <el-radio v-for="(item,index) in hotelList" :label="item.id" v-if="index > 0">{{ item.name }}</el-radio>
                  <el-radio :label="0">暂不分配</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="用餐点" label-width="120px" prop="eatHotelId">
                <el-radio-group v-model="editDLForm.eatHotelId">
                  <el-radio v-for="(item,index) in hotelList" :label="item.id" v-if="index > 0">{{ item.name }}</el-radio>
                  <el-radio :label="0">暂不分配</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="房间号" label-width="120px" prop="roomnum">
                <el-input size="small" v-model="editDLForm.roomnum" autocomplete="off" placeholder="请填写房间号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="酒店床位备注" label-width="120px" prop="jdNote">
                <el-input size="small" v-model="editDLForm.jdNote" autocomplete="off" placeholder="请填写酒店入住信息"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="当前行程状态" label-width="120px" prop="xingchengZt">
                <el-radio-group v-model="editDLForm.xingchengZt">
                  <el-radio v-for="(item,index) in xingchengZtList" :disabled="getXingchengZtDisabled(item.label)" :label="item.label" v-if="index > 0">{{ item.value }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="备注说明" label-width="120px" prop="dlNote">
                <el-input size="small" v-model="editDLForm.dlNote" autocomplete="off" placeholder="请输入备注"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
  
  
        </el-form>
  
        <div slot="footer" class="dialog-footer">
          <el-button @click="closetwo">关 闭</el-button>
          <el-button type="primary" @click.native.prevent="submitEditDL" :loading="editDLLoading">修改</el-button>
        </div>
      </el-dialog>
  
      <el-dialog title="excel导入" :visible.sync="excelImp.dialogVisible" width="60%" :close-on-click-modal="false">
        <el-form>
          <el-row>
            <el-col :span="24">
              <a :href="baseUrl+'/template.xlsx'" target="_blank">点击下载导入模板</a>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px;">
            <el-col :span="24">
              <el-upload
                class="upload-demo"
                ref="upload"
                name="file"
                :action="excelImp.action"
                :multiple="false"
                :limit="1"
                accept=".xls,.xlsx"
                :file-list="excelImp.fileList"
                :auto-upload="false"
                :on-success="onUploadSuccess"
                :on-error="onUploadError">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
  <!--              <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>-->
                <div slot="tip" class="el-upload__tip">只能上传xls/xlsx</div>
              </el-upload>
            </el-col>
          </el-row>
        </el-form>
  
        <div slot="footer" class="dialog-footer">
          <el-button @click="excelImp.dialogVisible = false">关 闭</el-button>
          <el-button type="primary" @click.native.prevent="submitUpload" :loading="excelImp.loading">立即导入</el-button>
        </div>
      </el-dialog>
  
      <el-dialog title="导出行程图片" :visible.sync="xctpDialogVisible" width="80%" :close-on-click-modal="false">
        <el-form>
          <el-row>
            <div style="padding-bottom: 20px; font-size: 20px; color: red;">导出图片请认真核对后使用</div>
            <el-col :span="8">
              <vue-canvas-poster v-if="genPoster" :widthPixels="0" :painting="painting" @success="genXCTPSuccess" @fail="genXCTPFail"></vue-canvas-poster>
            </el-col>
            <el-col :span="8">
              <img :src="xctpImg" style="width: 360px;height: 630px;mode: aspectFill"></img>
            </el-col>
            <el-col :span="8" style="font-size: 20px;float: right;"></el-col>
          </el-row>
        </el-form>
  
        <div slot="footer" class="dialog-footer">
          <el-button @click="xctpDialogVisible = false">关 闭</el-button>
          <el-button type="primary" @click.native.prevent="saveXCTP" :loading="loading">保存图片</el-button>
        </div>
      </el-dialog>
  
      <!-- <bmgl ref="bmgl" @fetch-data="getDataList(currentPage)" />
      <queren ref="queren" @fetch-data="getDataList" />
      <editbm ref="editbm" @fetch-data="getDataList(currentPage)" /> -->
  
    </div>
  </template>
  
  <script>
// import bmgl from "../model/xingcheng.vue";
// import queren from "../model/hyxiangqing.vue"; //确认参加
// import editbm from "../model/editbm.vue";

import { validPhone } from "@/common/utils/validate.js";
import request from "@/common/utils/request.js";
import util from "@/common/utils/util.js";
import { getUserList, addUser, updateUser, deleteUser } from "@/api/user";
import { getUserTypeList } from "@/api/user_type";
import { getYycompanyList } from "@/api/yy_company";
import { getFrontUserList } from "@/api/front_user";
import {
  getParticipateListtwo,
  getParticipateList,
  addParticipate,
  updateParticipate,
  deleteParticipate,
  updateDLInfo,
  updateSortBy,
  shenhe,
  getParticipateGB,
  getParticipateSameNameJB,
} from "@/api/participate";

import { getPlateList, getPlateListWithGroup } from "@/api/plate";
import { getSystemSet } from "@/api/system_setting";
import { getHotelList } from "@/api/hotel";
import { getAllUserList } from "@/api/user";
import json from "@/assets/address.json";
import {
  getInvitationCode,
  sendYYSMS,
  sendYYEmail,
  sendYYSMSBatch,
  sendYYEmailBatch,
} from "@/api/invitation";
import axios from "axios";
import { getToken } from "@/common/utils/auth";
import { number } from "echarts";
import { gethuoche, getfeiji } from "@/api/getbanci";
export default {
  name: "allParticipateManage",
  //   components: { bmgl, queren, editbm },
  comments: {
    // VueCanvasPoster
  },
  data() {
    return {
      // 日期组件内的值- 默认为当天时间
      timeValue: {
        stockDate: this.getNowTime(),
        stockDatetwo: this.getNowTime(),
      },
      didashijiantwo: "",
      feijididadi: "",
      didashijian: "", //高铁抵达时间显示
      getfeijione: "",
      gaotiedaodaone: "", //高铁滴答滴点
      options11: [], //选择抵达方式高铁的地点
      gaotietwo: "", //高铁走的时间
      options9: [],
      options10: [],
      options12: [],
      gaotieone: "",
      livetime: "",
      leftshijian: "",
      formInline: {
        // user: "",
        // region: "",
        // 火车
        trainNum: "", //或测班次号
        date: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() < Date.now() - 8.64e7 || //不能选择之前的日期
            time.getTime() > Date.now() + 1000 * 3600 * 24 * 14 //这是校验不能选择超过15天的日期
          );
        },
      },
      formInlinetwo: {
        flightId: "", //航班号
        date: "", //；日期、
      },

      arravive: "",
      arravivetwo: "",

      shifadi: false,
      chufadi: false,
      options1: [],
      options2: [],
      options3: [],
      options4: [],

      value1: "",
      value2: "",
      restaurants: [],
      state: "",
      timeout: null,
      haha: "",
      restaurants: [],
      currentFactor: "",
      paixu: false,
      total: 0,
      pageSize: 20,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      editForm: {
        sortBy: "",
        id: 0,
        frontUserId: "",
        handlerId: "",
        username: "",
        phone: "",
        phoneType: "",
        email: "",
        gender: "",
        company: "",
        yslingdao: "",
        ysdesc: "",
        country: "中国",
        idcard: "",
        idCardType: 0,
        sheng: "",
        shi: "",
        xian: "",
        hyIds: [],
        hyId: "",
        userTypeId: [],
        yyCompanyId: "",
        workGroup: "",
        qy: "",
        note2: "",
        note: "",
        status: "",
        yq: "",
        yyType: "",
        faceImg: "",
        ppid: "",
        qyOther: "",
        post: "",
      },
      gbList: [],
      editRules: {
        username: [
          { required: true, message: "请输入参会人员姓名", trigger: "blur" },
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
        company: [
          { required: true, message: "请输入参会人员单位", trigger: "blur" },
        ],
        post: [
          { required: true, message: "请输入参会人员职务", trigger: "blur" },
        ],
        // country: [{ required: true, message: "请输入国籍", trigger: "blur" }],
        // sheng: [{ required: true, message: "请选择地址", trigger: "blur" }],
        // hyIds: [
        //   { required: true, message: "请选择邀请参加会议", trigger: "blur" },
        // ],
        userTypeId: [
          { required: true, message: "请选择嘉宾类型", trigger: "blur" },
        ],
        qy: [{ required: true, message: "请选择付费方式", trigger: "blur" }],
        status: [
          { required: true, message: "请选择是否参会", trigger: "blur" },
        ],
        yq: [
          {
            required: true,
            message: "请选择7天内是否到过疫情地区",
            trigger: "blur",
          },
        ],
        yyType: [
          { required: true, message: "请选择邀约类型", trigger: "blur" },
        ],
        token: "",
        phone: [
          // { required: true, message:"请输入手机号", trigger: 'blur'},
          {
            required: true,
            validator: validPhone,
            message: "手机号码格式错误",
            trigger: "blur",
          },
        ],
        email: [
          { type: "email", message: "请输入正确的邮箱地址", trigger: ["blur"] },
        ],
      },
      loadingDiv: null,

      searchForm: {
        flightId: "",
        date: "",
        cardId: "",
        phone: "",
        username: "",
        post: "",
        userTypeId: [],
        company: "",
        hyId: "",
        status: "",
        yyCompanyId: "",
        workGroup: "",
        hotelId: "",
        country: "",
        qy: "",
        handlerId: "",
        createUserId: "",
        xingchengZt: "",
        arriveTime1: "",
        arriveTime2: "",
        backTime1: "",
        backTime2: "",
        updateTime1: "",
        updateTime2: "",
        createTime1: "",
        createTime2: "",
        userTypeIdList: [1, 2, 3, 4, 5, 6],
        updateUserId: "",
        status: -2,
        xczt: "",
      },
      searchVisible: 1,
      Height: 60 + "px",
      date: {
        date1: "",
        date2: "",
        date3: "",
        date4: "",
      },
      plateList: [],
      statusList: [
        // 状态  -1取消  1通过 2暂不确定 -2嘉宾拒绝 -3删除嘉宾

        { value: 99, label: "审核+确定" },
        { value: 0, label: "审核中" },
        { value: 1, label: "审核通过" },
        { value: 3, label: "确定参加" },
        { value: 2, label: "暂不确定" },
        { value: -1, label: "取消参加" },
        { value: -2, label: "嘉宾拒绝" },
        { value: -3, label: "已删除" },
      ],
      yyCompanyList: [],
      workGroupList: [],
      hotelList: [],
      countryList: [
        { value: "", label: "请选择国家" },
        { value: "中国", label: "中国" },
        { value: "非中国", label: "非中国" },
      ],
      qyList: [
        { value: "", label: "请选择付费方式" },
        { value: "全部承担", label: "全部承担" },
        { value: "承担住宿餐饮", label: "承担住宿餐饮" },
        { value: "仅餐饮", label: "仅餐饮" },
        { value: "全自费", label: "全自费" },
        { value: "其他", label: "其他" },
        // {value: '食宿补差', label: '食宿补差'},
        // {value: '住宿补差', label: '住宿补差'},
      ],
      handlerUserList: [],
      userList: [],
      userList2: [],
      xingchengZtList: [
        { value: "", label: "请选择行程状态" },
        { value: "未到", label: "未到" },
        { value: "已接到", label: "已接到" },
        { value: "到星光岛", label: "到星光岛" },
        { value: "入住酒店", label: "入住酒店" },
        { value: "已入场", label: "已入场" },
        { value: "离店", label: "离店" },
        { value: "离青", label: "离青" },
      ],
      userTypeList: [],
      address: [],
      selectValue: "",
      defultProp: {
        label: "name",
        value: "name",
        children: "sub",
      },
      isIndeterminate: false,
      checkAll: false,
      frontUserList: [],

      genPoster: false,
      yyhDialogVisible: false,
      genImgTitle: "",
      yyhImg: "",
      imgQrcodeContent: "",
      imgUserName: "",
      genYYHRow: null,
      painting: {
        width: `1080px`,
        height: `1890px`,
        background: "#f4f5f7",
        views: {},
      },
      getcheci: {}, //获取高铁火车班次
      getcheliveci: {}, //获取likai高铁火车班次
      getfeijitwo: "",
      yyInfoRow: "",
      yyInfoCode: "",
      yyInfoSMS: "",
      XCXScanTime: "",
      H5ScanTime: "",
      yyInfoSendStatus: "",
      plateWithGroupList: [],

      editDLFormVisible: false,
      editDLLoading: false,
      editDLForm: {
        id: "",
        pid: "",
        username: "",
        phone: "",
        gender: "",

        esArriveTime: "", // 预计抵青时间
        arriveTime: "", // 抵青时间

        arriveMode: "", // 到达方式 '飞机', '高铁', '自驾'
        carnum: "", // 车牌号

        frequency: "", // 班次
        arriveStation: "", // 抵青接站口

        backTime: "", // 离青时间

        backMode: "", // 离青方式
        backCarnum: "", // 离青车牌号

        backFrequency: "", // 离青班次
        lkdd: "", // 离青送站口

        dqqy: "", // 当前权益

        hotelId: "", // 分配酒店
        eatHotelId: "", // 分配吃饭地点

        roomnum: "", // 房间号
        jdNote: "", // 酒店床位备注

        xingchengZt: "", // 行程状态
        xingchengZtCurrent: "", // 修改之前行程状态

        dlNote: "", // 抵离备注
      },
      editDLRules: {},
      addShowFlag: false,

      dlDate: {
        date1: "",
        date2: "",
        date3: "",
      },
      jzkList: [
        // 接/送站口
        { label: "胶东国际机场", value: "胶东国际机场" },
        { label: "青岛北站", value: "青岛北站" },
        { label: "青岛站", value: "青岛站" },
        { label: "青岛西站", value: "青岛西站" },
      ],

      editSortBy: {
        sortByEditValue: "",
        pid: "",
      },

      excelImp: {
        dialogVisible: false,
        loading: false,
        fileList: [],
        action: "",
        loadingDiv: "",
      },
      zhuangtai: "",
      checkTime: "",
      leixing: "",
      baseUrl: "",
      uploadActionUrl: "",
      avatarUploadLoadingDiv: "",

      tabsActiveName: "",
      xctpDialogVisible: false,
      xctpImg: "",
      xctpRow: "",
    };
  },
  watch: {
    "editForm.id": function (val) {
      if (this.editForm.id == 0) {
        this.editRules["password"] = [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      } else {
        this.editRules["password"] = [
          {
            required: false,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      }
    },
  },
  created() {
    let t = getToken();
    this.token = { "x-token": t };
    console.log(this.token);
    this.getDataList(this.currentPage);
  },

  mounted() {
    let offsetHeight = this.$refs.search_form.$el.offsetHeight;
    if (!offsetHeight) {
      offsetHeight = 0;
    }

    this.$nextTick(() => {
      this.tableHeight =
        document.documentElement.clientHeight - 150 - offsetHeight;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight =
          document.documentElement.clientHeight - 150 - offsetHeight;
      });
    };

    this.address = json;

    this.init();

    let t = getToken();
    this.excelImp.action =
      this.$adminApiUrl + "/participate/excel-imp?userType=0&_t=" + t;
    this.baseUrl = this.$baseUrl;
    this.uploadActionUrl = this.$adminApiUrl + "/uploadToQiNiu?_t=" + t;
  },
  methods: {
    chosedidaone(e) {
      const nowTime =
        this.timeValue.stockDate + " " + e.arrive_time + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();

      this.dlDate.date2 = thisTime;
      this.didashijian = thisTime;
    },
    queren(row, status) {
      this.$refs["queren"].showEdit(row, status);
      // this.$refs["queren"].parsePlate(row, status);
    },
    choosepall(e) {
      console.log(e.START_TIME);
      //敌情飞机时间
      const nowTime = e.START_DATE + " " + e.START_TIME + ":" + "00";

      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date2 = e.start;

      this.getfeijione = e.start;
    },
    chooseone(e) {
      console.log(e, "始发地");
      const nowTime =
        this.timeValue.stockDate + " " + e.start_time + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();

      this.gaotieone = thisTime;
    }, //选择抵达时间
    choosetwoall(e) {
      //高铁的离青时间
      const nowTime = this.livetime + " " + e.start_time + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date3 = thisTime;
      this.gaotietwo = nowTime;
    },
    chooseall(e) {
      //飞机的离青时间
      const nowTime = e.START_DATE + " " + e.START_TIME + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date3 = thisTime;
      this.getfeijitwo = nowTime;
    },
    choosetwo() {
      var str = this.leftshijian.date;
      var a1 = str.indexOf(" ");
      var a2 = str.substring(0, a1); //字符串截取

      //飞机抵达时间

      const nowTime = a2 + " " + this.arravivetwo + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date2 = thisTime;
    }, //选择抵达时间
    choosethree() {
      var str = this.leftshijian;
      var a1 = str.indexOf(" ");
      var a2 = str.substring(0, a1); //字符串截取

      //飞机离开时间

      const nowTime = a2 + " " + this.arravivetwo + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date3 = thisTime;
    },
    getNowTime() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;
      return defaultDate;
    }, //获取当前时间

    //新增查询同步
    querySearch(queryString, callback) {
      // let JsonData = {
      //   validBegNm: "2022-06-02",
      // };
      // JsonData.validBegNm.replace("-", "");
      // console.log(JsonData.validBegNm, "测试是否去除字符");
      // 结果： 20220602

      let originalString = this.timeValue.stockDate;
      let reg = new RegExp("-", "g"); //去除字符串中所有的-
      let datas = originalString.replace(reg, "");

      axios
        .get("https://search.12306.cn/search/v1/train/search", {
          params: {
            keyword: queryString,
            date: datas,
          },
        })
        .then((res) => {
          let nameAndId = res.data.data;
          callback(nameAndId);
          // console.log(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });

      // axios({
      //   method: "get",
      //   url: "https://search.12306.cn/search/v1/train/search", // 请求地址
      //   data: {
      //     keyword: queryString,
      //     date: this.value2,
      //   }, // 参数
      //   // headers: headers,
      // })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    handleSelect(item) {
      console.log(this.timeValue.stockDate);
      this.editDLForm.frequency = item.station_train_code;
      this.getcheci = item;
    },
    handleSelectwo(item) {
      this.editDLForm.backFrequency = item.station_train_code;
      this.getcheliveci = item;
    },
    xuanze() {
      console.log(this.editDLForm.arriveMode);
    },
    onSubmit() {
      let that = this;
      this.options1 = [];
      this.options2 = [];
      this.options11 = [];
      if (!that.editDLForm.frequency) {
        this.$alert("请选择高铁班次号", {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showClose: false,
        });
      } else {
        axios
          .get("https://ybhcms.gengduoke.com/otn/queryTrainInfo/query", {
            // https://ybhcms.gengduoke.com/otn/queryTrainInfo/query
            //在宝塔配置反向代理，ybhcms.gengduoke.com是域名，queryTrainInfo/query是请求的路径，otn是在vue。config。js配置的请求地址
            //https://kyfw.12306.cn/otn/queryTrainInfo/query
            params: {
              "leftTicketDTO.train_no": that.getcheci.train_no,
              "leftTicketDTO.train_date": that.timeValue.stockDate,
              rand_code: "",
            },
          })
          .then((res) => {
            console.log(res.data.data);
            this.options1 = res.data.data.data;
            this.options2 = res.data.data.data;
            this.options11 = res.data.data.data;

            this.shifadi = true;
            console.log(res.data.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onSubmitfour() {
      this.options9 = [];
      this.options10 = [];
      let that = this;
      if (!that.editDLForm.backFrequency) {
        this.$alert("请选择高铁班次号", {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showClose: false,
        });
      } else {
        axios
          .get("https://ybhcms.gengduoke.com/otn/queryTrainInfo/query", {
            // https://ybhcms.gengduoke.com/otn/queryTrainInfo/query
            //在宝塔配置反向代理，ybhcms.gengduoke.com是域名，queryTrainInfo/query是请求的路径，otn是在vue。config。js配置的请求地址
            //https://kyfw.12306.cn/otn/queryTrainInfo/query
            params: {
              "leftTicketDTO.train_no": that.getcheliveci.train_no,
              "leftTicketDTO.train_date": that.livetime,
              rand_code: "",
            },
          })
          .then((res) => {
            this.options9 = res.data.data.data;
            this.options10 = res.data.data.data;
            this.chufadi = true;
            console.log(res.data.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onSubmithree() {
      this.options3 = [];
      this.options4 = [];
      let that = this;
      if (!that.editDLForm.backFrequency) {
        this.$alert("请选择航班班次号", {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showClose: false,
        });
        return;
      } else if (!that.leftshijian) {
        this.$alert("请选择航班时间", {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showClose: false,
        });
        return;
      } else {
        axios
          .get(this.$baseUrl + "/adminapi/plain-select", {
            params: {
              date: that.leftshijian,
              frequency: that.editDLForm.backFrequency,
            },
          })
          .then((res) => {
            this.chufadi = true;
            this.options3 = res.data.data;
            this.options4 = res.data.data;
            console.log(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    didafeijishijian(e) {
      console.log(e.end, "抵青方式飞机");
      const nowTime = e.END_DATE + " " + e.END_TIME + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date2 = e.end;
      this.didashijiantwo = e.end;
    },
    onSubmitwo() {
      this.options1 = [];
      this.options2 = [];
      this.options12 = [];
      let that = this;
      if (!that.editDLForm.frequency) {
        this.$alert("请选择航班班次号", {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showClose: false,
        });
        return;
      } else if (!that.searchForm.date) {
        this.$alert("请选择航班时间", {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showClose: false,
        });
        return;
      } else {
        axios
          .get(this.$baseUrl + "/adminapi/plain-select", {
            params: {
              date: that.searchForm.date,
              frequency: that.editDLForm.frequency,
            },
          })
          .then((res) => {
            this.shifadi = true;
            this.options1 = res.data.data;
            this.options2 = res.data.data;
            this.options12 = res.data.data;
            if (res.data.data[0].start_CITY == "") {
              this.$alert(
                "很抱歉，未查询与您输入相关的航班信息，请效验您的输入时间或者航班信息；目前实时航班起落信息，仅支持当天与未来时间，不支持历史时间查询",
                {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                  showClose: false,
                }
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // getfeiji({
      //   flightId: this.searchForm.flightId,
      //   date: this.searchForm.date,
      // })
      //   .then((res) => {
      //     console.log(res);
      //     // if (res.code == 1) {
      //     //   if (res.data > 0) {
      //     //     this.$alert("该嘉宾姓名已存在", {
      //     //       dangerouslyUseHTMLString: true,
      //     //       type: "warning",
      //     //       showClose: false,
      //     //     });
      //     //   }
      //     // }
      //   })
      //   .catch((err) => {});
    },
    // 1. 先获取数据
    getProductList() {
      listProduct(this.productParams).then((response) => {
        let restaurants = response.rows;
        restaurants.map((item, index) => {
          this.restaurants.push({
            // 只展示这个数组里的value值，受理商标等
            value: item.proName,
            price: item.price,
          });
        });
      });
    },

    // 2. 模糊搜索，输入值与获取到的数据进行比较
    proIdSearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    createFilter(queryString) {
      return (state) => {
        return state.value.indexOf(queryString) === 0;
      };
    },

    // 3. 选中值后，需要做的处理
    proIdSelect(item) {
      this.quotationDetails.filter((value) => {
        if (value.proId && value.proId === item.value) {
          value.price = item.price;
        }
      });
    },
    querySearchAsync(queryString, cb) {
      //高铁的离开方式获取车次
      let originalString = this.livetime;
      let reg = new RegExp("-", "g"); //去除字符串中所有的-
      let datas = originalString.replace(reg, "");

      axios
        .get("https://search.12306.cn/search/v1/train/search", {
          params: {
            keyword: queryString,
            date: datas,
          },
        })
        .then((res) => {
          let nameAndId = res.data.data;
          cb(nameAndId);
          // console.log(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    sousuo() {
      getYycompanyList().then((res) => {
        if (res.code) {
          this.yyCompanyList = res.data;
          this.yyCompanyList.unshift({ name: "请选择邀宾单位/部门", id: "" });
        }
      });
    },
    partTypesChanged(e) {
      console.log(e);
      console.log(this.searchForm.hyId);
    },
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1 || columnIndex == 2 || columnIndex == 3) {
        return "background:	#FFF8DC;";
      }
    },
    xuanzhong(e) {
      console.log(e.id);
      console.log(this.currentFactor.id);
    },
    saveXCTP() {
      let a = document.createElement("a");
      a.href = this.xctpImg;
      a.setAttribute("download", this.xctpRow.username + "_行程单");
      a.click();
    },
    genXCTP(index, row) {
      this.xctpImg = "";

      let color = "#FFFFFF";

      let line1 = "尊敬的 " + row.username + " ";
      if (row.gender == 1) {
        line1 += "先生：";
      } else if (row.gender == 2) {
        line1 += "女士：";
      }

      let xcinfo = this.genXingChenginfo(row);

      console.log(row.handlerNickname);
      console.log(row.handlerPhone);
      let llyName = row.handlerNickname;
      let llyPhone = row.handlerPhone;
      if (util.isEmpty(llyName)) {
        llyName = "　　　　";
      }
      if (util.isEmpty(llyPhone)) {
        llyPhone = "";
      }

      let lly = "联络员：" + llyName + " 联系方式：" + llyPhone;

      this.painting.views = [
        {
          type: "image",
          url: "https://cdn.ybh.gengduoke.com/10111.jpg",
          css: {
            width: `1080px`,
            height: `1890px`,
            top: "0",
          },
        },
        {
          type: "text",
          text: "行程单",
          css: {
            top: `440px`,
            left: `440px`,
            fontWeight: "500",
            color: "#fce28a",
            fontSize: "81px",
          },
        },
        {
          type: "text",
          text: line1,
          css: {
            top: `580px`,
            left: `80px`,
            fontWeight: "400",
            color: color,
            fontSize: "38px",
          },
        },
        {
          type: "text",
          text: "　　您好！欢迎您参加2023青岛影视周，您的大会行程安排如下：",
          css: {
            top: `640px`,
            left: `80px`,
            fontWeight: "400",
            color: color,
            width: "950px",
            fontSize: "33px",
            lineHeight: "55px",
          },
        },
        {
          type: "text",
          text: " " + xcinfo,
          css: {
            top: `780px`,
            left: `80px`,
            fontWeight: "400",
            color: color,
            width: "950px",
            fontSize: "33px",
            lineHeight: "55px",
          },
        },
        {
          type: "text",
          text: lly,
          css: {
            top: `1550px`,
            left: `250px`,
            fontWeight: "400",
            color: color,
            width: "950px",
            fontSize: "33px",
            lineHeight: "55px",
          },
        },
        {
          type: "text",
          text: "祝您在青岛东方影都度过愉快地三天！",
          css: {
            top: `1640px`,
            left: `120px`,
            fontWeight: "400",
            color: color,
            width: "950px",
            fontSize: "33px",
            lineHeight: "55px",
          },
        },
        {
          type: "text",
          text: "2023青岛影视周组委会",
          css: {
            top: `1750px`,
            left: `450px`,
            fontWeight: "400",
            color: color,
            width: "950px",
            fontSize: "33px",
            lineHeight: "55px",
          },
        },
      ];

      this.genPoster = true;
      this.xctpRow = row;
      this.xctpDialogVisible = true;
    },
    genXingChenginfo(row) {
      let plateList = row.plateList;
      if (util.isEmpty(plateList)) {
        return "";
      }

      // 按时间排序
      let pl = JSON.parse(JSON.stringify(plateList));
      pl.sort(function (x, y) {
        if (x < y) {
          return 1;
        } else if (x > y) {
          return -1;
        } else {
          return 0;
        }
      });

      let ret = "";
      for (let i in pl) {
        ret +=
          "　　" +
          this.fmtDate2(pl[i].startTime, "MM-dd hh:mm") +
          "，" +
          pl[i].address +
          "，" +
          pl[i].title;
        if (i < pl.length) {
          ret += "\n\r";
        }
      }

      console.log(ret);
      return ret;
    },

    genXCTPSuccess(val) {
      this.genPoster = false;
      this.xctpImg = val;
    },
    genXCTPFail(val) {
      this.$alert("生成失败");
      this.yyhDialogVisible = false;
    },

    checkSameName(e) {
      if (util.isNotEmpty(this.editForm.username)) {
        getParticipateSameNameJB({ username: this.editForm.username })
          .then((res) => {
            if (res.code == 1) {
              if (res.data > 0) {
                this.$alert("该嘉宾姓名已存在", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                  showClose: false,
                });
              }
            }
          })
          .catch((err) => {});
      }
    },
    fmtSx(row) {
      if (util.isEmpty(row.sxList)) {
        return "";
      }

      let sxStr = "";
      let sxList = row.sxList;
      for (let i in sxList) {
        sxStr += sxList[i].username + "(" + sxList[i].phone + ")<br />";
      }
      console.log(sxStr);
      return sxStr;
    },
    getParticipateGB() {
      getParticipateGB({ my: false })
        .then((res) => {
          if (res && res.code == 1) {
            console.log(res.data);
            this.gbList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onUserTypeChange(e) {
      // if (util.isEmpty(this.editForm.qy)){
      if (e == 1 || e == 2) {
        this.editForm.qy = "仅餐饮";
      }
      if (e == 3 || e == 4) {
        this.editForm.qy = "全部承担";
      }
      // }
    },
    handleAvatarSuccess(res, file, fileList) {
      this.avatarUploadLoadingDiv.close();
      if (res.code == 1) {
        // this.$message({
        //   message: '文件上传成功',
        //   type: 'success'
        // });
        this.editForm.faceImg = res.data;
        return;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    beforeAvatarUpload() {
      this.avatarUploadLoadingDiv = util.showLoading("照片上传中");
    },
    handleAvatarError() {
      this.avatarUploadLoadingDiv.close();
      this.$message({
        message: "照片上传失败,请检查网络",
        type: "warning",
      });
    },
    onUploadSuccess(res, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();

      if (res.code == 0) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "warning",
        });
      } else if (res.code == 1) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "success",
        }).then(() => {
          this.getDataList(1);
          this.excelImp.dialogVisible = false;
        });
      }
    },
    onUploadError(err, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();
      this.$alert("导入失败", {
        dangerouslyUseHTMLString: true,
        type: "warning",
      });
    },
    showForm() {
      if (this.searchVisible == 1) {
        this.Height = 120 + "px";
        this.searchVisible = -1;
      } else {
        this.Height = 60 + "px";
        this.searchVisible = 1;
      }
    },

    submitUpload() {
      this.excelImp.loading = true;
      this.excelImp.loadingDiv = util.showLoading("导入中");
      this.$refs.upload.submit();
    },
    handleExcelImp() {
      this.excelImp.fileList = [];
      this.excelImp.dialogVisible = true;
    },
    submitSH(index, row) {
      console.log(row);

      this.handleEdit(index, row);
      this.$confirm(
        "嘉宾姓名: [" + row.username + "] ,是否让嘉宾继续参会?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          const loadingDiv = util.showLoading("设置继续参会中");
          updateParticipate(this.editForm)
            .then((res) => {
              if (res.code == 0) {
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.getDataList(this.currentPage);
                });
              }
              loadingDiv.close();
            })
            .catch((res) => {
              loadingDiv.close();
            });
        })
        .catch(() => {});
    },
    showEditSortBy(row, index) {
      const loadingDiv = util.showLoading("修改排序中...");
      this.editSortBy.pid = row.id;
      this.editSortBy.sortByEditValue = row.sortBy;
      this.$prompt("请输入排序", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /(^[1-9]\d*$)/,
        inputErrorMessage: "请输入正整数",
        inputValue: row.sortBy,
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          updateSortBy({
            pid: this.editSortBy.pid,
            id: this.editSortBy.pid,
            sortBy: value,
          })
            .then((res) => {
              loadingDiv.close();
              if (res.code == 0) {
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$message({ message: res.msg, type: "success" });
                this.getDataList(this.currentPage);
                // this.$alert(res.msg, {dangerouslyUseHTMLString:true,type:'success'})
                //   .then(res=>{
                //     this.getDataList(this.currentPage)
                //   })
              }
            })
            .catch(() => {
              loadingDiv.close();
              this.$alert("修改失败,请检查您的网络", {
                dangerouslyUseHTMLString: true,
                type: "warning",
              });
            });
        })
        .catch(() => {
          loadingDiv.close();
          // this.$message({
          //   type: 'info',
          //   message: '取消输入'
          // });
        });
    },
    closetwo() {
      this.editDLFormVisible = false;
      let row = {};
      this.feditDLFormorm = Object.assign({}, row);
    },
    submitEditDL() {
      console.log("修改抵离信息");
      console.log(this.editDLForm);
      const loadingDiv = util.showLoading("修改抵离信息中");

      if (util.isNotEmpty(this.dlDate.date1) && this.dlDate.date1 > 0) {
        this.editDLForm.esArriveTime = this.dlDate.date1 / 1000;
      } else {
        this.editDLForm.esArriveTime = "";
      }
      if (util.isNotEmpty(this.dlDate.date2) && this.dlDate.date2 > 0) {
        this.editDLForm.arriveTime = this.dlDate.date2 / 1000;
      } else {
        this.editDLForm.arriveTime = "";
      }
      if (util.isNotEmpty(this.dlDate.date3) && this.dlDate.date3 > 0) {
        this.editDLForm.backTime = this.dlDate.date3 / 1000;
      } else {
        this.editDLForm.backTime = "";
      }

      updateDLInfo(this.editDLForm)
        .then((res) => {
          if (res.code == 0) {
            this.$alert(res.msg, {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then(() => {
              this.closetwo();

              this.getDataList(this.currentPage);
            });
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },

    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D = date.getDate() + " ";
      let h = date.getHours() + ":";
      let m = date.getMinutes() + ":";
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    handleEditDL(index, row) {
      if (row.arriveTime) {
        this.didashijian = this.timestampToTime(row.arriveTime);
        this.didashijiantwo = this.timestampToTime(row.arriveTime);
      }
      if (row.backTime) {
        this.gaotietwo = this.timestampToTime(row.backTime);
        this.getfeijitwo = this.timestampToTime(row.backTime);
      }
      if (row.frequency) {
        this.editDLForm.frequency = row.frequency;
      }
      console.log(row.frequency);
      console.log(row.backTime);
      this.editDLForm.pid = row.id;
      this.editDLForm.id = row.id;

      this.editDLForm.username = row.username;
      this.editDLForm.phone = row.phone;
      this.editDLForm.gender = row.gender;
      this.editDLForm.fromCity = row.fromCity;
      // 预计抵青时间
      this.editDLForm.esArriveTime = row.esArriveTime;
      if (util.isNotEmpty(row.esArriveTime) && row.esArriveTime > 0) {
        this.dlDate.date1 = row.esArriveTime * 1000;
      }

      // 抵青时间
      this.editDLForm.arriveTime = row.arriveTime;
      if (util.isNotEmpty(row.arriveTime) && row.arriveTime > 0) {
        this.dlDate.date2 = row.arriveTime * 1000;
      }

      this.editDLForm.arriveMode = row.arriveMode; // 到达方式 '飞机', '高铁', '自驾'
      this.editDLForm.carnum = row.carnum; // 车牌号
      this.editDLForm.frequency = row.frequency; // 班次
      this.editDLForm.arriveStation = row.arriveStation; // 抵青接站口

      // 离青时间
      this.editDLForm.backTime = row.backTime;
      if (util.isNotEmpty(row.backTime) && row.backTime) {
        this.dlDate.date3 = row.backTime * 1000;
      }

      this.editDLForm.backMode = row.backMode; // 离青方式
      this.editDLForm.backCarnum = row.backCarnum; // 离青车牌号
      this.editDLForm.backFrequency = row.backFrequency; // 离青班次
      this.editDLForm.lkdd = row.lkdd; // 离青送站口
      this.editDLForm.dqqy = row.qy; // 权益
      this.editDLForm.hotelId = row.hotelId; // 分配酒店
      this.editDLForm.eatHotelId = row.eatHotelId; // 分配吃饭地点
      this.editDLForm.roomnum = row.roomnum; // 房间号
      this.editDLForm.jdNote = row.jdNote; // 酒店床位备注
      this.editDLForm.xingchengZt = row.xingchengZt; // 行程状态
      this.editDLForm.xingchengZtCurrent = row.xingchengZt; // 修改之前行程状态
      this.editDLForm.dlNote = row.dlNote; // 抵离备注

      this.editDLFormVisible = true;
    },
    getXingchengZtDisabled(label) {
      if (util.isEmpty(this.editDLForm.xingchengZtCurrent)) {
        return false;
      }

      let labelIndex = -1;
      let currentIndex = -1;
      for (let i in this.xingchengZtList) {
        if (this.xingchengZtList[i].label == label) {
          labelIndex = i;
        }
        if (
          this.xingchengZtList[i].label == this.editDLForm.xingchengZtCurrent
        ) {
          currentIndex = i;
        }
      }

      if (labelIndex < currentIndex) {
        return true;
      }
      return false;
    },
    sendYYSMS() {
      let loadingDiv;
      this.$confirm("确认发送短信邀请函？", "发送确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("发送邀请短信中");
            return sendYYSMS({
              pid: this.yyInfoRow.id,
            });
          }
        })
        .then((res) => {
          loadingDiv.close();
          this.$alert(res.msg);
        })
        .catch((err) => {
          loadingDiv.close();
          this.$alert("发送失败,请检查网络。");
        });
    },
    sendYYEmail() {
      let loadingDiv;
      this.$confirm("确认发送邀请邮件？", "发送确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("发送邀请邮件中");
            return sendYYEmail({
              pid: this.yyInfoRow.id,
            });
          }
        })
        .then((res) => {
          loadingDiv.close();
          this.$alert(res.msg);
        })
        .catch((err) => {
          loadingDiv.close();
          this.$alert("发送失败,请检查网络。");
        });
    },
    sendYQSMSBatch() {
      let selection = this.$refs.elTable.selection;
      if (!selection || selection.length <= 0) {
        this.$alert("请选择嘉宾");
        return;
      }

      let pids = [];
      for (let i = 0; i < selection.length; i++) {
        pids.push(selection[i].id);
      }

      let loadingDiv;

      this.$confirm("确认批量发送短信邀请函？", "发送确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("发送邀请短信中");
            return sendYYSMSBatch({
              pids: pids,
            });
          }
        })
        .then((res) => {
          loadingDiv.close();
          this.$alert(res.msg);
        })
        .catch((err) => {
          loadingDiv.close();
          this.$alert("发送失败,请检查网络。");
        });
    },
    sendYQEmailBatch() {
      let selection = this.$refs.elTable.selection;
      if (!selection || selection.length <= 0) {
        this.$alert("请选择嘉宾");
        return;
      }

      let pids = [];
      for (let i = 0; i < selection.length; i++) {
        pids.push(selection[i].id);
      }

      let loadingDiv;

      this.$confirm("确认批量发送邮件邀请函？", "发送确认")
        .then((res) => {
          if ((res = "confirm")) {
            loadingDiv = util.showLoading("发送邀请邮件中");
            return sendYYEmailBatch({
              pids: pids,
            });
          }
        })
        .then((res) => {
          loadingDiv.close();
          this.$alert(res.msg);
        })
        .catch((err) => {
          loadingDiv.close();
          this.$alert("发送失败,请检查网络。");
        });
    },
    saveImg() {
      let a = document.createElement("a");
      a.href = this.yyhImg;
      a.setAttribute("download", this.genYYHRow.username + "的邀请函");
      a.click();
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D = date.getDate() + " ";
      let h = date.getHours() + ":";
      let m = date.getMinutes() + ":";
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    genYYH(index, row) {
      this.yyhImg = "";
      this.genYYHRow = row;
      this.genImgTitle = "邀请函生成中";
      this.yyInfoCode = "";
      this.yyInfoSMS = "";
      this.yyInfoSendStatus = "";
      this.yyInfoRow = row;

      getInvitationCode({ p_id: row.id }).then((res) => {
        if (res.code != 1 || util.isEmpty(res.data)) {
          this.$alert(res.msg, {
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
          return;
        }
        this.checkTime = this.timestampToTime(res.data.checkTime);
        if (res.data.agreeType == 1) {
          this.leixing = "本人操作";
        } else {
          this.leixing = "其他人操作";
        }
        if (res.data.status == 1) {
          //状态  -1取消 0审核中 1审核通过 2暂不确定 -2嘉宾拒绝 -3删除嘉宾 3确认参加 -9暂存
          this.zhuangtai = "审核通过";
        } else if (res.data.status == -1) {
          this.zhuangtai = "取消";
        } else if (res.data.status == 0) {
          this.zhuangtai = "审核中";
        } else if (res.data.status == 2) {
          this.zhuangtai = "暂不确定";
        } else if (res.data.status == -2) {
          this.zhuangtai = "嘉宾拒绝";
        } else if (res.data.status == -3) {
          this.zhuangtai = "删除嘉宾";
        } else if (res.data.status == 3) {
          this.zhuangtai = "确认参加";
        } else if (res.data.status == 9) {
          this.zhuangtai = "暂存";
        }

        this.yyInfoCode = res.data.code;
        this.XCXScanTime = res.data.XCXScanTime;
        this.H5ScanTime = res.data.H5ScanTime;
        this.yyInfoSMS =
          "【青岛影视周】尊敬的" +
          row.username +
          "，诚邀您参加·2023青岛影视周，访问 y.qdysblh.com/#/?code=" +
          this.yyInfoCode +
          " 可以反馈您的参会行程，期待您的莅临。";

        this.painting.views = [
          {
            type: "image",
            url: "https://cdn.ybh.gengduoke.com/yaoqinghan3.jpg",
            css: {
              width: `1080px`,
              height: `1890px`,
              top: "0",
            },
          },
          {
            type: "qrcode",
            content: "https://y.qdysblh.com/" + this.yyInfoCode,
            css: {
              top: `1330px`,
              left: `385px`,
              color: "#000",
              width: `320px`,
              height: `320px`,
              borderColor: "#FFF",
              borderWidth: "15px",
              background: "#FFF",
            },
          },
          {
            type: "text",
            text: row.username,
            css: {
              top: `609px`,
              left: `230px`,
              color: "#FFF",
              fontSize: "37px",
            },
          },
        ];

        this.genPoster = true;
        this.yyhDialogVisible = true;
      });
    },
    genYYHSuccess(val) {
      this.genImgTitle = "邀请函生成成功";
      this.genPoster = false;
      this.yyhImg = val;
      if (this.genYYHLoadingDiv) {
        this.genYYHLoadingDiv.close();
      }
    },
    genYYHFail(val) {
      this.genImgTitle = "";
      this.genPoster = false;
      this.$alert("生成邀请函失败");
      this.yyhDialogVisible = false;
    },
    handleCheckAllChange(val) {
      if (val) {
        let ids = [];
        for (let i in this.plateList) {
          ids.push(this.plateList[i].id);
        }
        this.editForm.hyIds = ids;
      } else {
        this.editForm.hyIds = [];
      }
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      console.log(this.editForm.hyIds);
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.plateList.length - 1;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.plateList.length - 1;
    },
    handleAddressChange() {
      if (this.selectValue.length == 2) {
        this.editForm.sheng = this.selectValue[0];
        this.editForm.shi = this.selectValue[0];
        this.editForm.xian = this.selectValue[1];
      } else {
        this.editForm.sheng = this.selectValue[0];
        this.editForm.shi = this.selectValue[1];
        this.editForm.xian = this.selectValue[2];
      }
    },
    clear() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      for (let key in this.date) {
        this.date[key] = "";
      }

      this.searchForm.userTypeIdList = [1, 2, 3, 4, 5, 6];
      this.getDataList(1);
    },
    init() {
      getUserTypeList().then((res) => {
        if (res.code) {
          let userTypeList = [];
          userTypeList.push({ name: "请选择嘉宾类型", id: "" });
          // this.userTypeList = res.data;
          // this.userTypeList.unshift({name: "请选择嘉宾类型", id:''})
          for (let i in res.data) {
            if (
              res.data[i].id == 1 ||
              res.data[i].id == 2 ||
              res.data[i].id == 3 ||
              res.data[i].id == 4 ||
              res.data[i].id == 5 ||
              res.data[i].id == 6
            ) {
              userTypeList.push(res.data[i]);
            }
          }

          this.userTypeList = userTypeList;
        }
      });

      // getPlateList().then(res=>{
      //   if (res.code){
      //     this.plateList = res.data;
      //     this.plateList.unshift({title: "请选择会议", id:''})
      //   }
      // });
      getYycompanyList().then((res) => {
        if (res.code) {
          this.yyCompanyList = res.data;
          this.yyCompanyList.unshift({ name: "请选择邀宾单位/部门", id: "" });
        }
      });

      getSystemSet({ group: "work_group" }).then((res) => {
        if (res.code) {
          // this.yyCompanyList = res.data;
          this.workGroupList.unshift({ title: "请选择部门/组", value: "" });
          for (let i in res.data) {
            this.workGroupList.push({
              title: res.data[i].title,
              value: res.data[i].title,
            });
          }
        }
      });

      getHotelList().then((res) => {
        if (res.code) {
          this.hotelList = res.data;
          this.hotelList.unshift({ id: "", name: "请选择酒店" });
        }
      });

      getAllUserList().then((res) => {
        if (res.code) {
          this.userList = JSON.parse(JSON.stringify(res.data));
          this.userList2 = JSON.parse(JSON.stringify(res.data));
          this.handlerUserList = res.data;

          this.userList.unshift({ id: "", nickname: "请选择管理员" });
          this.userList2.unshift({ id: "", nickname: "请选择最后修改人" });
          this.handlerUserList.unshift({ id: "", nickname: "请选择联络员" });
        }
      });

      // 按照分组获取会议
      getPlateListWithGroup({ isZy: 0 }).then((res) => {
        this.plateWithGroupList = res.data;
        if (res.code) {
          this.tabsActiveName = res.data[0].title;
        }
      });
    },
    parsePlate(row, status) {
      if (util.isNotEmpty(row.plateList)) {
        let str = "";
        let index = 1;
        let plateList = row.plateList;
        // for (let i in plateList) {
        //   if (util.isEmpty(plateList[i])) {
        //     continue;
        //   }
        str += row.plateList.length;
        // str +=
        //   "<div class='ellipsis'>" +
        //   index +
        //   ".<" +
        //   plateList[i].title +
        //   "></div>";
        // index++;
        // }

        if (status == 1) {
          if (row.status == 1 || row.status == 3) {
            return "共" + str + "条";
          }
        } else if (status == 0) {
          if (row.status == 2) {
            return "共" + str + "条";
          }
        }
      } else {
        return "";
      }
    },
    fmtDate2(d2, fmt) {
      // yyyy-MM-dd hh:mm:ss
      if (util.isEmpty(d2) || d2 <= 0) {
        return "";
      }
      return this.fmtDate(new Date(d2 * 1000), fmt);
    },
    fmtDate(d, fmt) {
      // yyyy-MM-dd hh:mm:ss
      if (!fmt) {
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      var o = {
        "M+": d.getMonth() + 1, //月份
        "d+": d.getDate(), //日
        "h+": d.getHours(), //小时
        "m+": d.getMinutes(), //分
        "s+": d.getSeconds(), //秒
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度
        S: d.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (d.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    showAddDialogForm() {
      this.getParticipateGB();
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }

      if (this.frontUserList && this.frontUserList.length <= 0) {
        getFrontUserList().then((res) => {
          if (res.code) {
            this.frontUserList = res.data;
          }
        });
      }

      for (let key in this.editForm) {
        this.editForm[key] = "";
      }
      this.addShowFlag = false;
      this.editForm.id = 0;
      this.editForm.country = "中国";
      this.editForm.idCardType = 0;
      this.editForm.hyIds = [];
      this.selectValue = "";
      this.tabsActiveName = this.plateWithGroupList[0].title;

      this.editFormVisible = true;
    },
    handleEdit(index, row) {
      console.log(row.sortBy);
      this.getParticipateGB();
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }

      if (this.frontUserList && this.frontUserList.length <= 0) {
        getFrontUserList().then((res) => {
          if (res.code) {
            this.frontUserList = res.data;
          }
        });
      }

      this.addShowFlag = true;
      if (row.sortBy) {
        this.paixu = true;
        this.editForm.sortBy = row.sortBy;
      }

      this.editForm.id = row.id;
      this.editForm.frontUserId = row.frontUserId;
      this.editForm.handlerId = row.handlerId;
      this.editForm.username = row.username;
      this.editForm.phone = row.phone;
      this.editForm.gender = row.gender;
      this.editForm.company = row.company;
      this.editForm.post = row.post;
      this.editForm.yslingdao = row.yslingdao;
      this.editForm.ysdesc = row.ysdesc;
      this.editForm.country = row.country;
      this.editForm.idcard = row.idcard;
      this.editForm.idCardType = row.idCardType;
      this.editForm.sheng = row.sheng;
      this.editForm.shi = row.shi;
      this.editForm.xian = row.xian;
      this.editForm.email = row.email;
      this.editForm.phoneType = row.phoneType;
      this.editForm.faceImg = row.faceImg;
      this.editForm.ppid = row.ppid;
      this.editForm.qyOther = row.qyOther;
      this.editForm.note2 = row.note2;

      if (row.sheng == row.shi) {
        this.selectValue = [row.sheng, row.xian];
      } else {
        this.selectValue = [row.sheng, row.shi, row.xian];
      }

      let hyIds = [];
      if (row.plateList && row.plateList.length > 0) {
        for (let i in row.plateList) {
          hyIds.push(row.plateList[i].plateId);
        }
      }
      this.editForm.hyIds = hyIds;
      this.editForm.userTypeId = row.userTypeId;
      this.editForm.yyCompanyId = row.yyCompanyId;
      this.editForm.workGroup = row.workGroup;
      this.editForm.qy = row.qy;
      this.editForm.note = row.note;
      //   this.editForm.status = row.status;
      this.editForm.status = 3;
      this.editForm.yq = row.yq;
      this.editForm.yyType = row.yyType;
      this.tabsActiveName = this.plateWithGroupList[0].title;

      //   this.editFormVisible = true;
    },
    submitForm() {
      //   console.log(this.editForm.sortBy);
      // var regPos = /^\+?[1-9]\d*$/; // 非负整数
      // if(regPos.test(this.editForm.sortBy)){
      //   this.$message.error("请排序输入正整数");
      //   return;
      // }
      if (this.editForm.userTypeId == 6 && util.isEmpty(this.editForm.ppid)) {
        this.$message.error("请选择贵宾");
      }

      if (this.editForm.userTypeId != 6) {
        this.editForm.ppid = "";
      }

      if (this.editForm.status == 3) {
        if (util.isEmpty(this.editForm.idcard)) {
          this.$message.error("请填写证件号码");
          return;
        }
      }

      if (this.editForm.status == 3) {
        if (util.isEmpty(this.editForm.phone)) {
          this.$message.error("请填写手机号码");
          return;
        }
      }

      if (util.isNotEmpty(this.editForm.phone)) {
        if (util.isEmpty(this.editForm.phoneType)) {
          this.$message.error("请选择电话类型");
          return;
        }
      }

      this.$refs.editForm.validate((valid, val) => {
        if (!valid) {
          let msg = "";
          for (let key in val) {
            msg = val[key][0].message;
            break;
          }
          this.$message.error(msg);
        }

        if (this.editForm.qy == "其他") {
          if (util.isEmpty(this.editForm.qyOther)) {
            this.$message.error("请填写付费方式");
            return;
          }
        } else {
          this.editForm.qyOther == "";
        }

        if (valid) {
          this.editForm.hyId = this.editForm.hyIds.join(",");

          this.loading = true;
          let loadingText = "添加中...";
          if (this.editForm.id) {
            loadingText = "修改中...";
          }
          const loadingDiv = util.showLoading(loadingText);
          let func = updateParticipate;
          if (this.editForm.id == 0) {
            func = addParticipate;
          }
          func(this.editForm)
            .then((res) => {
              let title = "修改";
              if (this.editForm.id == 0) {
                title = "添加";
              }
              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  let pNum = 1;
                  this.editFormVisible = false;
                  if (this.editForm.id > 0) {
                    pNum = this.currentPage;
                  }
                  this.getDataList(pNum);
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handlexingcheng(row) {
      if (row.id) {
        this.$refs["bmgl"].showEdit(row);
      } else {
        this.$refs["bmgl"].showEdit();
      }
    },
    handlebumen(row) {
      if (row.id) {
        this.$refs["editbm"].showEdit(row);
      } else {
        this.$refs["editbm"].showEdit();
      }
    },
    handleDelete(index, row) {
      let loadingDiv;
      this.$prompt("请输入删除原因", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          if (util.isEmpty(value)) {
            this.$message.error("请填写删除原因");
            this.handleDelete(index, row);
            return;
          } else {
            let loadingDiv = util.showLoading("删除中...");
            deleteParticipate({ pid: row.id, deleteReason: value })
              .then((res) => {
                loadingDiv.close();
                // console.log("===================")
                // console.log(res)
                if (res.code == 0) {
                  this.$alert(res.msg, "删除失败", {
                    dangerouslyUseHTMLString: true,
                    type: "warning",
                    showClose: false,
                  });
                } else if (res.code == 1) {
                  this.$alert(res.msg, "删除成功", {
                    dangerouslyUseHTMLString: true,
                    type: "success",
                    showClose: false,
                  }).then((res) => {
                    this.editFormVisible = false;
                    this.getDataList(this.currentPage);
                  });
                }
              })
              .catch((res) => {
                if (loadingDiv) {
                  loadingDiv.close();
                }
              });
          }
        })
        .catch((res) => {
          // if (loadingDiv){
          //   loadingDiv.close();
          // }
        });
    },

    getDataList(pageNum) {
      console.log("wewqeewq");
      const loadingDiv = util.showLoading();

      let param = {
        pageSize: this.pageSize,
        pageNum: pageNum,
      };

      if (util.isNotEmpty(this.date.date1) && this.date.date1.length >= 2) {
        this.searchForm.arriveTime1 = this.date.date1[0] / 1000;
        this.searchForm.arriveTime2 = this.date.date1[1] / 1000;
      } else {
        this.searchForm.arriveTime1 = "";
        this.searchForm.arriveTime2 = "";
      }

      if (util.isNotEmpty(this.date.date2) && this.date.date2.length >= 2) {
        this.searchForm.backTime1 = this.date.date2[0] / 1000;
        this.searchForm.backTime2 = this.date.date2[1] / 1000;
      } else {
        this.searchForm.backTime1 = "";
        this.searchForm.backTime2 = "";
      }

      if (util.isNotEmpty(this.date.date3) && this.date.date3.length >= 2) {
        this.searchForm.updateTime1 = this.date.date3[0] / 1000;
        this.searchForm.updateTime2 = this.date.date3[1] / 1000;
      } else {
        this.searchForm.updateTime1 = "";
        this.searchForm.updateTime2 = "";
      }

      if (util.isNotEmpty(this.date.date4) && this.date.date4.length >= 2) {
        this.searchForm.createTime1 = this.date.date4[0] / 1000;
        this.searchForm.createTime2 = this.date.date4[1] / 1000;
      } else {
        this.searchForm.createTime1 = "";
        this.searchForm.createTime2 = "";
      }

      for (var i in this.searchForm) {
        param[i] = this.searchForm[i];
      }

      getParticipateList(param)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;
            this.plateList = data.plateList;
            this.plateList.unshift({ title: "请选择会议", id: "" });
            this.currentPage = pageNum;
            this.total = data.total;
            this.tableData = data.dataList;
            if (data.plateList) {
              this.plateList = data.plateList;
            }
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.getDataList(pageNum);
    },
    getDataListtwo(pageNum) {
      const loadingDiv = util.showLoading();

      let param = {
        pageSize: this.pageSize,
        pageNum: pageNum,
      };

      if (util.isNotEmpty(this.date.date1) && this.date.date1.length >= 2) {
        this.searchForm.arriveTime1 = this.date.date1[0] / 1000;
        this.searchForm.arriveTime2 = this.date.date1[1] / 1000;
      } else {
        this.searchForm.arriveTime1 = "";
        this.searchForm.arriveTime2 = "";
      }

      if (util.isNotEmpty(this.date.date2) && this.date.date2.length >= 2) {
        this.searchForm.backTime1 = this.date.date2[0] / 1000;
        this.searchForm.backTime2 = this.date.date2[1] / 1000;
      } else {
        this.searchForm.backTime1 = "";
        this.searchForm.backTime2 = "";
      }

      if (util.isNotEmpty(this.date.date3) && this.date.date3.length >= 2) {
        this.searchForm.updateTime1 = this.date.date3[0] / 1000;
        this.searchForm.updateTime2 = this.date.date3[1] / 1000;
      } else {
        this.searchForm.updateTime1 = "";
        this.searchForm.updateTime2 = "";
      }

      if (util.isNotEmpty(this.date.date4) && this.date.date4.length >= 2) {
        this.searchForm.createTime1 = this.date.date4[0] / 1000;
        this.searchForm.createTime2 = this.date.date4[1] / 1000;
      } else {
        this.searchForm.createTime1 = "";
        this.searchForm.createTime2 = "";
      }

      for (var i in this.searchForm) {
        param[i] = this.searchForm[i];
      }

      getParticipateListtwo(param)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;
            this.plateList = data.plateList;
            this.plateList.unshift({ title: "请选择会议", id: "" });
            this.currentPage = pageNum;
            this.total = data.total;
            this.tableData = data.dataList;
            if (data.plateList) {
              this.plateList = data.plateList;
            }
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getDataList(1);
    },
    exportExcel() {
      const loadingDiv = util.showLoading("正在导出EXCEL");

      let param = {};

      if (util.isNotEmpty(this.date.date1) && this.date.date1.length >= 2) {
        this.searchForm.arriveTime1 = this.date.date1[0] / 1000;
        this.searchForm.arriveTime2 = this.date.date1[1] / 1000;
      }

      if (util.isNotEmpty(this.date.date2) && this.date.date2.length >= 2) {
        this.searchForm.backTime1 = this.date.date2[0] / 1000;
        this.searchForm.backTime2 = this.date.date2[1] / 1000;
      }

      if (util.isNotEmpty(this.date.date3) && this.date.date3.length >= 2) {
        this.searchForm.updateTime1 = this.date.date3[0] / 1000;
        this.searchForm.updateTime2 = this.date.date3[1] / 1000;
      }

      if (util.isNotEmpty(this.date.date4) && this.date.date4.length >= 2) {
        this.searchForm.createTime1 = this.date.date4[0] / 1000;
        this.searchForm.createTime2 = this.date.date4[1] / 1000;
      }

      for (var i in this.searchForm) {
        param[i] = this.searchForm[i];
      }

      let t = getToken();
      let headers = { "x-token": t };
      axios({
        method: "post",
        url: this.$adminApiUrl + "/participate/export-excel", // 请求地址
        data: param, // 参数
        headers: headers,

        responseType: "blob", // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          loadingDiv.close();
          console.log(res);
          let blob = new Blob([res.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");
          link.download = "按搜索条件导出嘉宾_" + timeStr + ".xlsx"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          loadingDiv.close();
          this.$alert("导出失败,请检查网络", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
        });
    },
    exportExcelhuiyi(e) {
      console.log(e);
      if (!e) {
        this.$confirm("请选择导出会议项");
      } else {
        const loadingDiv = util.showLoading("正在导出EXCEL");
        let t = getToken();
        let headers = { "x-token": t };
        axios({
          method: "post",
          url: this.$adminApiUrl + "/participate/export-excel-meeting", // 请求地址
          data: {
            hyId: e,
            userTypeIdList: this.searchForm.userTypeIdList,
          }, // 参数
          headers: headers,
          responseType: "blob", // 表明返回服务器返回的数据类型
        })
          .then((res) => {
            loadingDiv.close();
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a"); // 创建a标签
            link.href = url;
            let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");

            let fileName = "会议人员表";

            link.download = fileName + "_" + timeStr + ".xlsx"; // 重命名文件
            link.click();
            URL.revokeObjectURL(url);
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    exportExcelgeren(e) {
      if (e == undefined) {
        this.$confirm("请选择导出项");
      } else {
        const loadingDiv = util.showLoading("正在导出EXCEL");
        let t = getToken();
        let headers = { "x-token": t };
        axios({
          method: "post",
          url: this.$adminApiUrl + "/participate/export-excel-aboutone", // 请求地址
          data: {
            id: e,
          }, // 参数
          headers: headers,
          responseType: "blob", // 表明返回服务器返回的数据类型
        })
          .then((res) => {
            loadingDiv.close();
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a"); // 创建a标签
            link.href = url;
            let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");

            let fileName = "个人行程表";

            link.download = fileName + "_" + timeStr + ".xlsx"; // 重命名文件
            link.click();
            URL.revokeObjectURL(url);
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    exportExcelSameName() {
      const loadingDiv = util.showLoading("正在导出EXCEL");

      let t = getToken();
      let headers = { "x-token": t };
      axios({
        method: "post",
        url: this.$adminApiUrl + "/participate/export-excel-same-name", // 请求地址
        headers: headers,

        responseType: "blob", // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          loadingDiv.close();
          let blob = new Blob([res.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");

          let fileName = "姓名重复嘉宾信息表";

          link.download = fileName + "_" + timeStr + ".xlsx"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          loadingDiv.close();
          this.$alert("导出失败,请检查网络", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
        });
    },
    exportExcelNew(num) {
      const loadingDiv = util.showLoading("正在导出EXCEL");

      let param = { num: num };
      let t = getToken();
      let headers = { "x-token": t };
      axios({
        method: "post",
        url: this.$adminApiUrl + "/participate/export-excel-new", // 请求地址
        data: param, // 参数
        headers: headers,

        responseType: "blob", // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          loadingDiv.close();
          let blob = new Blob([res.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");

          let fileName = "2023青岛影视周嘉宾信息表";
          switch (num) {
            case 10:
              fileName = "2023青岛影视周嘉宾信息表";
              break;
            case 11:
              fileName = "2023青岛影视周政务贵宾信息表";
              break;
            case 12:
              fileName = "2023青岛影视周政务嘉宾信息表";
              break;
            case 13:
              fileName = "2023青岛影视周行业贵宾信息表";
              break;
            case 14:
              fileName = "2023青岛影视周行业嘉宾信息表";
              break;
          }

          link.download = fileName + "_" + timeStr + ".xlsx"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          loadingDiv.close();
          this.$alert("导出失败,请检查网络", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
        });
    },
  },
};
</script>
  
  <style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
  <style lang="scss">
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-tabs--border-card {
  box-shadow: none;
}
.fengmiantu {
  width: 50px;
  height: 50px;
}
</style>
  