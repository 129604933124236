import request from '@/common/utils/request'
import Vue from "vue";

export function gethuoche(params) {
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/train-select',
    method: 'get',
    params
  })
}
export function getfeiji(data) {
    return request({
      url: Vue.prototype.$baseUrl + '/adminapi/plain-select',
      method: 'post',
      data
    })
  }